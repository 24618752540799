import * as React from 'react';
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, } from '@ant-design/icons';
import Modal, { destroyFns } from './Modal';
import confirm from './confirm';
function modalWarn(props) {
    const config = Object.assign({ type: 'warning', icon: <ExclamationCircleOutlined />, okCancel: false }, props);
    return confirm(config);
}
Modal.info = function infoFn(props) {
    const config = Object.assign({ type: 'info', icon: <InfoCircleOutlined />, okCancel: false }, props);
    return confirm(config);
};
Modal.success = function successFn(props) {
    const config = Object.assign({ type: 'success', icon: <CheckCircleOutlined />, okCancel: false }, props);
    return confirm(config);
};
Modal.error = function errorFn(props) {
    const config = Object.assign({ type: 'error', icon: <CloseCircleOutlined />, okCancel: false }, props);
    return confirm(config);
};
Modal.warning = modalWarn;
Modal.warn = modalWarn;
Modal.confirm = function confirmFn(props) {
    const config = Object.assign({ type: 'confirm', okCancel: true }, props);
    return confirm(config);
};
Modal.destroyAll = function destroyAllFn() {
    while (destroyFns.length) {
        const close = destroyFns.pop();
        if (close) {
            close();
        }
    }
};
export default Modal;
