import * as React from 'react';
import classNames from 'classnames';
import omit from 'omit.js';
import ClearableLabeledInput, { hasPrefixSuffix } from './ClearableLabeledInput';
import { ConfigConsumer } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import warning from '../_util/warning';
export function fixControlledValue(value) {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return value;
}
export function resolveOnChange(target, e, onChange) {
    if (onChange) {
        let event = e;
        if (e.type === 'click') {
            // click clear icon
            event = Object.create(e);
            event.target = target;
            event.currentTarget = target;
            const originalInputValue = target.value;
            // change target ref value cause e.target.value should be '' when clear input
            target.value = '';
            onChange(event);
            // reset target ref value
            target.value = originalInputValue;
            return;
        }
        onChange(event);
    }
}
export function getInputClassName(prefixCls, size, disabled, direction) {
    return classNames(prefixCls, {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
}
class Input extends React.Component {
    constructor(props) {
        super(props);
        this.direction = 'ltr';
        this.saveClearableInput = (input) => {
            this.clearableInput = input;
        };
        this.saveInput = (input) => {
            this.input = input;
        };
        this.handleReset = (e) => {
            this.setValue('', () => {
                this.focus();
            });
            resolveOnChange(this.input, e, this.props.onChange);
        };
        this.renderInput = (prefixCls, size) => {
            const { className, addonBefore, addonAfter, size: customizeSize, disabled } = this.props;
            // Fix https://fb.me/react-unknown-prop
            const otherProps = omit(this.props, [
                'prefixCls',
                'onPressEnter',
                'addonBefore',
                'addonAfter',
                'prefix',
                'suffix',
                'allowClear',
                // Input elements must be either controlled or uncontrolled,
                // specify either the value prop, or the defaultValue prop, but not both.
                'defaultValue',
                'size',
                'inputType',
            ]);
            return (<input {...otherProps} onChange={this.handleChange} onKeyDown={this.handleKeyDown} className={classNames(getInputClassName(prefixCls, customizeSize || size, disabled, this.direction), {
                [className]: className && !addonBefore && !addonAfter,
            })} ref={this.saveInput}/>);
        };
        this.clearPasswordValueAttribute = () => {
            // https://github.com/ant-design/ant-design/issues/20541
            this.removePasswordTimeout = setTimeout(() => {
                if (this.input &&
                    this.input.getAttribute('type') === 'password' &&
                    this.input.hasAttribute('value')) {
                    this.input.removeAttribute('value');
                }
            });
        };
        this.handleChange = (e) => {
            this.setValue(e.target.value, this.clearPasswordValueAttribute);
            resolveOnChange(this.input, e, this.props.onChange);
        };
        this.handleKeyDown = (e) => {
            const { onPressEnter, onKeyDown } = this.props;
            if (e.keyCode === 13 && onPressEnter) {
                onPressEnter(e);
            }
            if (onKeyDown) {
                onKeyDown(e);
            }
        };
        this.renderComponent = ({ getPrefixCls, direction }) => {
            const { value } = this.state;
            const { prefixCls: customizePrefixCls } = this.props;
            const prefixCls = getPrefixCls('input', customizePrefixCls);
            this.direction = direction;
            return (<SizeContext.Consumer>
        {size => (<ClearableLabeledInput {...this.props} prefixCls={prefixCls} inputType="input" value={fixControlledValue(value)} element={this.renderInput(prefixCls, size)} handleReset={this.handleReset} ref={this.saveClearableInput} direction={direction}/>)}
      </SizeContext.Consumer>);
        };
        const value = typeof props.value === 'undefined' ? props.defaultValue : props.value;
        this.state = {
            value,
            // eslint-disable-next-line react/no-unused-state
            prevValue: props.value,
        };
    }
    static getDerivedStateFromProps(nextProps, { prevValue }) {
        const newState = { prevValue: nextProps.value };
        if (nextProps.value !== undefined || prevValue !== nextProps.value) {
            newState.value = nextProps.value;
        }
        return newState;
    }
    componentDidMount() {
        this.clearPasswordValueAttribute();
    }
    // Since polyfill `getSnapshotBeforeUpdate` need work with `componentDidUpdate`.
    // We keep an empty function here.
    componentDidUpdate() { }
    getSnapshotBeforeUpdate(prevProps) {
        if (hasPrefixSuffix(prevProps) !== hasPrefixSuffix(this.props)) {
            warning(this.input !== document.activeElement, 'Input', `When Input is focused, dynamic add or remove prefix / suffix will make it lose focus caused by dom structure change. Read more: https://ant.design/components/input/#FAQ`);
        }
        return null;
    }
    componentWillUnmount() {
        if (this.removePasswordTimeout) {
            clearTimeout(this.removePasswordTimeout);
        }
    }
    focus() {
        this.input.focus();
    }
    blur() {
        this.input.blur();
    }
    select() {
        this.input.select();
    }
    setValue(value, callback) {
        if (this.props.value === undefined) {
            this.setState({ value }, callback);
        }
    }
    render() {
        return <ConfigConsumer>{this.renderComponent}</ConfigConsumer>;
    }
}
Input.defaultProps = {
    type: 'text',
};
export default Input;
